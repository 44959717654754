import React from 'react';

import { FormatErrorMessage } from './FormatErrorMessage';

import './FormatRawPreview.scss';

const RAW_PREVIEW_SIZE = 5;

export const FormatRawPreview: React.FC<{
  chunk: string;
  warning?: Papa.ParseError;
  onCancelClick: () => void;
  skipRows: number;
  // eslint-disable-next-line react/display-name
}> = React.memo(({ chunk, warning, onCancelClick, skipRows }) => {
  const newLines: number[] = [];
  let lastIndex = 0;
  while (lastIndex < chunk.length -1) {
    const nextIndex = chunk.slice(lastIndex + 1).indexOf('\n');
    if(nextIndex === -1) {
      lastIndex = chunk.length;
      break;
    }
    console.log(lastIndex, nextIndex);
    newLines.push(nextIndex + lastIndex + 1);
    lastIndex += nextIndex + 1;
  }
  console.log(skipRows, newLines)
  const chunkSlice = chunk.slice(newLines[Math.min(newLines.length -1, skipRows)], newLines[Math.min(newLines.length -1, skipRows + RAW_PREVIEW_SIZE)] - newLines[Math.min(newLines.length -1, skipRows)]);
  const chunkHasMore = chunk.length > RAW_PREVIEW_SIZE;

  return (
    <div className="CSVImporter_FormatRawPreview">
      <div className="CSVImporter_FormatRawPreview__scroll">
        <pre className="CSVImporter_FormatRawPreview__pre">
          {chunkSlice}
          {chunkHasMore && <aside>...</aside>}
        </pre>
      </div>

      {warning ? (
        <FormatErrorMessage onCancelClick={onCancelClick}>
          {warning.message || String(warning)}: please check data formatting
        </FormatErrorMessage>
      ) : null}
    </div>
  );
});
