import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Menu } from "antd";
import {UserOutlined, DashboardOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom";
import Logo from "../assets/images/notores-logo";

const { Sider } = Layout;

const MenuItems = ({ all, currentItem, onMenuItemClick }) => {
  if (all) {
    return (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
        <Menu.Item key="/">
          <DashboardOutlined />
          <span>Home</span>
          <Link to={'/'}/>
        </Menu.Item>
        <Menu.Item key="/import/customers">
          <UserOutlined />
          <span>Klantenbestand</span>
          <Link to={'/import/customers'}/>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
      <Menu.Item key="/">
        <DashboardOutlined />
        <span>Home</span>
        <Link to={'/'}/>
      </Menu.Item>
    </Menu>
  )
};

const SidebarMenu = ({ collapsed, verifying }) => {
  let [currentItem, updateCurrentItem] = useState(useLocation().pathname || '/');

  const onMenuItemClick = ({ item, key, keyPath }) => {
    updateCurrentItem(key);
  };

  if (currentItem.indexOf('/orders/process') > -1) {
    currentItem = '/';
  }
  else if (currentItem.startsWith('/products/')) {
    currentItem = '/products';
  }

  return (
    <Fragment>
      <Sider trigger={null} collapsible collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
        <div className="flex row justify-content-center logo">
          <Logo/>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
          <MenuItems all={!verifying} defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}/>
        </Menu>
      </Sider>
    </Fragment>
  )
};

export default SidebarMenu;
