import React, { Component } from 'react';
import { Layout} from 'antd';
import { withRouter } from "react-router-dom";
import Flex from "../components/layout/Flex";
import SidebarMenu from "./SidebarMenu";
import {LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import "../scss/header.scss";
import {logoutUser} from '../redux/actions/Actions';
import {connect} from 'react-redux';

const { Header, Content } = Layout;

class DefaultLayout extends Component {

  state = {
    collapsedState: true,
  };

  toggleCollapsed = () => this.setState({ collapsedState: !this.state.collapsedState });

  render() {
    const { collapsedState, automaticPrinting, printQueueLength } = this.state;
    const { toggleCollapsed } = this;

    return (
      <Layout>
        <SidebarMenu collapsed={collapsedState} toggleCollapse={toggleCollapsed}
          verifying={this.props.verifying}/>
        <Layout style={{ marginLeft: collapsedState ? 80 : 200 }}>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Flex row alignItems={'center'} justifyContent={'space-between'}>
              <Flex>
                {
                  collapsedState ?
                    <MenuUnfoldOutlined className="trigger" onClick={toggleCollapsed} /> :
                    <MenuFoldOutlined className="trigger" onClick={toggleCollapsed} />
                }
              </Flex>
              <Flex justifyContent={'flex-end'}>
                <LogoutOutlined className="trigger" onClick={this.props.logout} />
              </Flex>
            </Flex>

          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
