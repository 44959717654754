import React, {Fragment, useState} from 'react';
import { Typography} from 'antd';
import Flex from "../components/layout/Flex";
import {Importer, ImporterField} from '../components/react-csv-importer';
import {patchManyCustomers} from '../lib/serverCommunication';

const { Title } = Typography;

const ImportCustomersPage = () => {

  const [data, setData] = useState<{ i: number; a: number; b?: number }[]>([]);
  return (
    <Fragment>
      <Flex row justifyContent={'space-between'}>
        <Title>Importeer Klantenbestand</Title>
      </Flex>
      <div>
        <Importer<{ a: string; b: string }>
          chunkSize={5000} // intentionally small chunk size for interactive display
          restartable
          onStart={({ file }) => {
           setData([]);
           console.log(`Importing ${file.name}`);
          }}
          onComplete={({ file }) => {
           console.log(`Finished importing ${file.name}`);
          }}
          processChunk={async (rows) => {
            console.log(rows);
            await patchManyCustomers(rows);

          }}
      >
        <ImporterField name="winbooksCode" label="WinBooks Code" />
        <ImporterField name="name" label="Klantnaam" optional />
        <ImporterField name="phoneNumber" label="Telefoonnummer" optional />
        <ImporterField name="emailAddress" label="E-mailadres" optional />
        <ImporterField name="addressStreet" label="Adres" optional />
        <ImporterField name="addressPostalCode" label="Postcode" optional />
        <ImporterField name="addressCity" label="Plaats" optional />
        <ImporterField name="invoiceStreet" label="Factuur adres" optional />
        <ImporterField name="invoicePostalCode" label="Factuur postcode" optional />
        <ImporterField name="invoiceCity" label="Factuur plaats" optional />
        <ImporterField name="winbooksPaycode" label="Winbooks Betaalcode" optional />

      </Importer>
    </div>
    </Fragment>
  );
};

export default ImportCustomersPage;
