import LoadingIcon from 'antd/es/button/LoadingIcon';
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import {
  connect,
  Provider
} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom";
import Icon from '@ant-design/icons';
import DefaultLayout from "./layout/DefaultLayout";
import ImportCustomersPage from './pages/ImportCustomers';
import {
  store,
  persistor
} from './redux/configureStore';
import MainPage from "./pages/MainPage";
import Login from "./pages/Login";
import Flex from "./components/layout/Flex";
import { logoutUser } from "./redux/actions/Actions";
import { getProfile } from "./lib/serverCommunication";

const AuthRoutes = withRouter(connect(
  // @ts-ignore
  state => ({ auth: state.auth }),
  dispatch => ({
    logout: () => dispatch(logoutUser())
  })
)(function AuthRoutes(props) {
  const [verifyingAuth, updateVerifyingAuth] = useState(true);

  useEffect(() => {
    // @ts-ignore
    if (!props.auth.jwt) {
      return;
    }
    getProfile()
      .then(body => {
        console.log(body);
        if (body.user && body.user.roles && Array.isArray(body.user.roles) && body.user.roles.includes('admin')) {
          return updateVerifyingAuth(false);
        }
        else {
          // @ts-ignore
          return props.logout();
        }
      })
  });

  // @ts-ignore
  if (!props.auth.jwt) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          // @ts-ignore
          state: { from: props.location }
        }}
      />
    )
  }

  if (verifyingAuth) {
    return (
      // @ts-ignore
      <DefaultLayout verifying>
        <Flex center style={{ width: '100%', height: '100%' }}>
          <LoadingIcon
            // @ts-ignore
            spin
            style={{ fontSize: '3rem' }}/>
        </Flex>
      </DefaultLayout>
    )
  }

  return (
    <Router>
      <DefaultLayout>
        <Switch>
          <Route exact path="/">
            <MainPage/>
          </Route>
          <Route exact path="/import/customers">
            <ImportCustomersPage />
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  )
}));

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login/>
            </Route>
            <Route path="/">
              <AuthRoutes/>
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
