/*
 * action types
 */
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';

// PRODUCT
export const SET_PRODUCTS = 'SET_PRODUCTS';

/*
 * action creators
 */
export function authenticateUser(user) {
  return { type: AUTHENTICATE, payload: user };
}

export function logoutUser() {
  return { type: LOGOUT };
}

export function setProducts(products) {
  return { type: SET_PRODUCTS, payload: products };
}
