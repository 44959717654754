import React from 'react';


const MainPage = () => {
  return (
    <>
      <h1>Title</h1>
    </>
  )
}

export default MainPage;
