import { store } from './../redux/configureStore';

const serverConfig = Object.freeze({
  serverUrl: 'https://server.carantos.appserver1.silicon.be'
  //serverUrl: 'http://localhost:5007'
})

const genFetch = async (url, options, query) => {
  let address;
  if (typeof url === 'string') {
    address = serverConfig.serverUrl + url;
  }
  else if (typeof url === 'object') {
    if (url.url.startsWith('http')) {
      address = url.url;
    }
    else {
      if (url.admin) {
        //
        address = `${serverConfig.serverUrl}/n-admin${url.url}`;
      }
      else {
        address = `${serverConfig.serverUrl}${url.url}`;
      }
    }
  }

  if (query) {
    address += '?';
    for (const k in query) {
      console.log(k);
      address += Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    }
  }

  return fetch(address, options)
    .then(res => {
      return res.json()
    });
};

const genOptions = (method = 'GET', body, auth = true) => {
  const options = {
    method: method.toUpperCase(),
    credentials: 'omit',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (auth) {
    const state = store.getState();
    const auth = state.auth;
    if (auth.jwt) {
      options.headers.Authorization = `Bearer ${auth.jwt}`;
    }
  }

  if (!['GET', 'DELETE'].includes(method)) {
    options.body = JSON.stringify(body);
  }

  return options;
};

/*******************************
 ************* USER ************
 *******************************/

export const getProfile = () => genFetch('/profile', genOptions());

export const getUsers = () => genFetch({ url: '/users', admin: true }, genOptions());

export const verifyEmailExists = email => genFetch(`/user/verify-email-exists/${email}`);

export const registerUser = userInfo => {
  const body = {
    ...userInfo,
  };

  return genFetch(`/register`, genOptions('post', body, false));
};

export const loginUser = ({ email, password }) => {
  const body = {
    email,
    password
  };

  return genFetch('/login', genOptions('post', body, false));
};

/*******************************
 *********** PRODUCTS **********
 *******************************/

export const getProducts = () => genFetch({ url: '/products', admin: true }, genOptions());
export const getCustomers = () => genFetch({ url: '/customers', admin: true }, genOptions());
export const patchManyCustomers = (body) => genFetch({ url: '/customers', admin: false }, genOptions('PATCH',body));
