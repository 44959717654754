import React, { useMemo, useRef, useEffect, useState } from 'react';

import {
  parsePreview,
  PreviewError,
  PreviewBase,
  CustomizablePapaParseConfig
} from './parser';
import { ImporterFrame } from './ImporterFrame';
import { FormatRawPreview } from './FormatRawPreview';
import { FormatDataRowPreview } from './FormatDataRowPreview';
import { FormatErrorMessage } from './FormatErrorMessage';

import './FormatPreview.scss';

export interface Preview extends PreviewBase {
  hasHeaders: boolean;
  skipRows: number;
}

export const FormatPreview: React.FC<{
  customConfig: CustomizablePapaParseConfig;
  file: File;
  assumeNoHeaders?: boolean;
  currentPreview: Preview | null;
  onChange: (preview: Preview | null) => void;
  onAccept: () => void;
  onCancel: () => void;
}> = ({
  customConfig,
  file,
  assumeNoHeaders,
  currentPreview,
  onChange,
  onAccept,
  onCancel
}) => {
  // augmented PreviewResults from parser
  const [preview, setPreview] = useState<
    | PreviewError
    | ({
        parseError: undefined;
      } & Preview)
    | null
  >(
    () =>
      currentPreview && {
        parseError: undefined,
        ...currentPreview
      }
  );

  // wrap in ref to avoid triggering effect
  const customConfigRef = useRef(customConfig);
  customConfigRef.current = customConfig;
  const assumeNoHeadersRef = useRef(assumeNoHeaders);
  assumeNoHeadersRef.current = assumeNoHeaders;
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // notify of current state
  useEffect(() => {
    // @ts-ignore
    onChangeRef.current(preview && !preview.parseError ? preview : null);
  }, [preview]);

  // perform async preview parse
  const asyncLockRef = useRef<number>(0);
  useEffect(() => {
    // avoid re-parsing if already set up a preview for this file
    // @ts-ignore
    if (preview && !preview.parseError && preview.file === file) {
      return;
    }

    const oplock = asyncLockRef.current;

    parsePreview(file, customConfigRef.current).then((results) => {
      // ignore if stale
      if (oplock !== asyncLockRef.current) {
        return;
      }

      if (results.parseError) {
        setPreview(results);
      } else {
        // pre-fill headers flag (only possible with >1 lines)
        // @ts-ignore
        const hasHeaders = !assumeNoHeadersRef.current && !results.isSingleLine;

        setPreview({ ...results, hasHeaders, skipRows: 0 });
      }
    });

    return () => {
      // invalidate current oplock on change or unmount
      asyncLockRef.current += 1;
    };
  }, [file, preview]);

  const report = useMemo(() => {
    if (!preview) {
      return null;
    }

    if (preview.parseError) {
      return (
        <div className="CSVImporter_FormatPreview__mainResultBlock">
          <FormatErrorMessage onCancelClick={onCancel}>
            Import error:{' '}
            <b>{preview.parseError.message || String(preview.parseError)}</b>
          </FormatErrorMessage>
        </div>
      );
    }

    return (
      <div className="CSVImporter_FormatPreview__mainResultBlock">
        <div className="CSVImporter_FormatPreview__header">
          Raw File Contents
        </div>

        <FormatRawPreview
          // @ts-ignore
          chunk={preview.firstChunk}
          // @ts-ignore
          warning={preview.parseWarning}
          onCancelClick={onCancel}
          skipRows={preview.skipRows || 0}
        />

        {
          // @ts-ignore
          preview.parseWarning ? null : (
          <>
            <div className="CSVImporter_FormatPreview__header">
              Preview Import
              {
                // @ts-ignore
                !preview.isSingleLine && ( // hide setting if only one line anyway
                <>
                  <label className="CSVImporter_FormatPreview__headerToggle">
                    <input
                      type="checkbox"
                      // @ts-ignore
                      checked={preview.hasHeaders}
                      onChange={() => {
                        setPreview((prev) =>
                          prev && !prev.parseError // appease type safety
                            ? {
                              ...prev,
                              // @ts-ignore
                              hasHeaders: !prev.hasHeaders
                            }
                            : prev
                        );
                      }}
                    />
                    <span>Data has headers</span>
                  </label>
                  <label className="CSVImporter_FormatPreview__headerToggle">
                    <input
                      type="number"
                      // @ts-ignore
                      value={preview.skipRows}
                      onChange={(event) => {
                        setPreview((prev) =>
                          prev && !prev.parseError // appease type safety
                            ? {
                              ...prev,
                              // @ts-ignore
                              skipRows: event.target.valueAsNumber
                            }
                            : prev
                        );
                      }}
                    />
                    <span>Ignore first rows</span>
                  </label>
                </>
              )}
            </div>
            <FormatDataRowPreview
              // @ts-ignore
              hasHeaders={preview.hasHeaders}
              // @ts-ignore
              rows={preview.firstRows}
              // @ts-ignore
              skipRows={preview.skipRows || 0}
            />
          </>
        )}
      </div>
    );
  }, [preview, onCancel]);

  return (
    <ImporterFrame
      fileName={file.name}
      // @ts-ignore
      nextDisabled={!preview || !!preview.parseError || !!preview.parseWarning}
      onNext={() => {
        if (!preview || preview.parseError) {
          throw new Error('unexpected missing preview info');
        }

        onAccept();
      }}
      onCancel={onCancel}
    >
      {report || (
        <div className="CSVImporter_FormatPreview__mainPendingBlock">
          Loading preview...
        </div>
      )}
    </ImporterFrame>
  );
};
